import React from 'react'
import { ProvideAnnouncement } from '../hooks/useAnnouncement'
import { ProvideToast } from '../hooks/useToast'

export const wrapRootElement: React.FC<any> = ({ element }) => {
  return (
    <ProvideAnnouncement>
      <ProvideToast>
        {element}
      </ProvideToast>
    </ProvideAnnouncement>)
}
