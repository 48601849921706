import React, { FC, useState, useContext, createContext } from 'react'

type ToastType = 'success' | 'error'
export type ToastContent = {
  headline: string
  description: string
  type: ToastType
}
export type Context = {
  isOpen: boolean
  close: () => void
  setContent: (content: ToastContent) => void
  content: ToastContent
}

const context: React.Context<Context> = createContext({
  isOpen: undefined,
  close: undefined,
  content: undefined,
  setContent: undefined,
})

function useProvideToast() {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [content, setContent] = useState<ToastContent>()

  return {
    isOpen,
    content,
    close: () => setIsOpen(false),
    setContent: (content: ToastContent) => {
      setContent(content)
      setIsOpen(true)
    }
  }
}

export const ProvideToast: FC = ({ children }) => {
  const toast = useProvideToast()
  return (
    <context.Provider
      value={{
        ...toast,
      }}
    >
      {children}
    </context.Provider>
  )
}

export const useToast = () => useContext(context)
