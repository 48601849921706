// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-become-a-mentor-tsx": () => import("./../../../src/templates/become-a-mentor.tsx" /* webpackChunkName: "component---src-templates-become-a-mentor-tsx" */),
  "component---src-templates-become-a-partner-tsx": () => import("./../../../src/templates/become-a-partner.tsx" /* webpackChunkName: "component---src-templates-become-a-partner-tsx" */),
  "component---src-templates-main-page-tsx": () => import("./../../../src/templates/main-page.tsx" /* webpackChunkName: "component---src-templates-main-page-tsx" */),
  "component---src-templates-newsletter-thanks-tsx": () => import("./../../../src/templates/newsletter-thanks.tsx" /* webpackChunkName: "component---src-templates-newsletter-thanks-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-posts-tsx": () => import("./../../../src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */),
  "component---src-templates-profile-mentor-tsx": () => import("./../../../src/templates/profile-mentor.tsx" /* webpackChunkName: "component---src-templates-profile-mentor-tsx" */),
  "component---src-templates-profile-partner-tsx": () => import("./../../../src/templates/profile-partner.tsx" /* webpackChunkName: "component---src-templates-profile-partner-tsx" */),
  "component---src-templates-schedule-tsx": () => import("./../../../src/templates/schedule.tsx" /* webpackChunkName: "component---src-templates-schedule-tsx" */),
  "component---src-templates-why-tsx": () => import("./../../../src/templates/why.tsx" /* webpackChunkName: "component---src-templates-why-tsx" */)
}

