import React, { FC, useState, useContext, createContext, useEffect } from 'react'

export type Context = {
  isOpen: boolean
  toggle: () => void
}

const context: React.Context<Context> = createContext({
  isOpen: undefined,
  toggle: undefined
})

function useProvideAnnouncement() {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const toggle = () => setIsOpen(!isOpen)
  return {
    isOpen,
    toggle
  }
}

export const ProvideAnnouncement: FC = ({ children }) => {
  const announcement = useProvideAnnouncement()
  return (
    <context.Provider
      value={{
        ...announcement,
      }}
    >
      {children}
    </context.Provider>
  )
}

export const useAnnouncement = () => useContext(context)
